import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scroll } from 'react-fns';
import styled from 'styled-components';

import { toggleCartModalAction, toggleCartDropdownAction } from '_store/ui';
import { navigationHeight, deviceSize, colors } from '_constants';
import { PhoneIcon } from '_components/phoneIcon';
import { ReactComponent as TransLogo } from '_images/connect.svg';
import { ReactComponent as FirstKeyLogo } from '_images/firstKey.svg';
import wfgImage from '_images/WFGNT.png';
import { isMobileDevice } from '_helpers';
import { Link } from 'react-router-dom';
import cartIcon from '_images/cart.svg';
import store from '_store';
import { push } from 'connected-react-router';
import { useEffect } from 'react/cjs/react.development';

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  background-color: white;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 60px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 153px;
  }
`;

const Logo = styled(TransLogo)`
  cursor: pointer;
  height: 75px;
  margin-left: 75px;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 25px;
    position: relative;
    right: 35px;
  }
`;

const LogoFK = styled(FirstKeyLogo)`
  cursor: pointer;
  height: 60px;
  margin-left: 50px;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 25px;
    position: relative;
    right: 35px;
  }
`;

const LogoWFG = styled.img`
  cursor: pointer;
  height: 75px;
  margin-left: 75px;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 25px;
    position: relative;
    right: 35px;
  }
`;

const CartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.brand};
  padding: 5px;
  width: 50px;
  height: 35px;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;
`;

const NoCartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: "#000000";
  padding: 5px;
  width: 50px;
  height: 35px;
  border-radius: 6px;
  margin-right: 20px;
  @media (max-width: ${deviceSize.tablet}px) {
    height: 0px;
    width: 0px;
    padding: 0px;
    margin-right: 0px;
    border-radius: 0px;
  }
`;

const BurgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

const BurgerLayer = styled.div`
  width: 15px;
  height: 3px;
  background-color: white;
`;

const CartIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.div`
  color: #4A4A4A;
  margin: 0 20px;
  font-weight: bold;
`;

const CartId = styled.div`
  cursor: pointer;
  color: white;
  margin: 10px 20px;
  font-weight: bold;
`;

const PhoneRow = styled.a`
  display: flex;
  align-items: center;
`;

const CartNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: white;
  color: black;
  position: absolute;
  font-size: 12px;
  z-index: 10;
  right: 25px;
  top: 35px;

  @media (max-width: ${deviceSize.tablet}px) {
    top: 15px;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 15px;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    display: none;
  }
`;

export const NavBar = ({ pathname, menuItems }) => {
  const reducer = (state) => state.uiReducer;
  const { isOpenCartModal, isOpenCartDropdown } = useSelector(reducer);

  const userReducer = (state) => state.userReducer;
  const { cart } = useSelector(userReducer);
  const selectedPlansInformation = Object.values(cart).filter(
    (service) => service !== null
  );

  const dispatch = useDispatch();

  const [isNotShopping, setIsNotShopping] = useState(false);

  useLayoutEffect(()=>{
    let urlString = String(window.location.href);
    if (urlString.includes("orderTracking")){
      setIsNotShopping(true);
    }
    else{
      setIsNotShopping(false);
    }
  });

  return (
    <Scroll
      render={({ y }) => (
        <StyledNavbar pathname={pathname} scrollHeight={y}>
          <>
            <LogoContainer>
            {
                window.location.href.indexOf("wfg") > -1 ?
                <LogoWFG src={wfgImage} onClick={() => {
                  store.dispatch(push('../home'));
                }}/>
                :
                undefined
              }
              {
                window.location.href.indexOf("firstkey") > -1 ? 
                <LogoFK pathname={pathname} onClick={() => {
                  store.dispatch(push('../home'));
                }}/>
                :
                <Logo pathname={pathname} onClick={() => {
                  store.dispatch(push('../home'));
                }}/>
                
              }
            </LogoContainer>
            <RightContainer>
              {menuItems ? <MenuItems>{menuItems}</MenuItems> : null}

              {
                window.location.href.indexOf("firstkey") > -1 ? 
                  <PhoneRow href="tel:1-844-387-7747">
                    <PhoneIcon color="#4A4A4A" />
                    <Number>844.387.7747</Number>
                    </PhoneRow>                
                :
                  <PhoneRow href="tel:1-844-406-1540">
                    <PhoneIcon color="#4A4A4A" />
                    <Number>844.406.1540</Number>
                  </PhoneRow>
              }
              {
                isNotShopping ?
                (
                  <NoCartContainer></NoCartContainer>
                )
                :
                (
                  <CartContainer
                    onClick={() => {
                      dispatch(toggleCartModalAction(!isOpenCartModal));
                    }}
                  >
                    <CartIcon src={cartIcon} />
                  </CartContainer>
                )
              }
            </RightContainer>
          </>
          {selectedPlansInformation.length > 0 && (
            <CartNumber>{selectedPlansInformation.length}</CartNumber>
          )}
        </StyledNavbar>
      )}
    />
  );
};
